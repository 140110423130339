export const SiteTypes = [
  "Mining",
  "Solar",
  "Wind",
  "Industrial",
  "Forestry",
  "Coal",
  "Agriculture",
  "Waste",
  "Oil",
  "Natural Gas",
  "Buildings",
];
