export const LOAD_PLEDGES = "[account] LOAD_PLEDGES";
export const ADD_PLEDGE = "[account] ADD_PLEDGE";
export const LOAD_TRANSFERS = "[account] LOAD_TRANSFERS";
export const ADD_TRANSFER = "[account] ADD_TRANSFER";
export const LOAD_SITES = "[account] LOAD_SITES";
export const LOAD_WALLETS = "[account] LOAD_WALLETS";
export const ADD_SITE = "[account] ADD_SITE";
export const LOAD_CLIMATE_ACTIONS = "[account] LOAD_CLIMATE_ACTIONS";
export const ADD_CLIMATE_ACTIONS = "[account] ADD_CLIMATE_ACTIONS";
export const LOAD_AGGREGATED_EMISSIONS = "[account] LOAD_AGGREGATED_EMISSIONS";
export const ADD_AGGREGATED_EMISSIONS = "[account] ADD_AGGREGATED_EMISSIONS";
export const ACCOUNT_CLEAR_STATE = "[account] ACCOUNT_CLEAR_STATE";
